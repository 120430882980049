/* eslint tailwindcss/no-custom-classname: off */
import { useState } from 'react'
import css from 'styled-jsx/css'

const styles = css`
  .short-description {
    position: relative;
    overflow: hidden;
  }
  .short-description::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 40px;
    width: 100%;
  }
  .full-description .short-description::after {
    height: 0;
    background: none;
  }
`

interface Props {
  children: React.ReactNode
  showHeight?: number
  align?: 'center' | 'right' | 'left'
  fontSize?: string
  fontWeight?: string
  margin?: string
  showAlways?: boolean
}
export const ReadMoreButton: React.FC<Props> = ({
  showHeight,
  children,
  align = 'left',
  fontSize = '14px',
  fontWeight = '100',
  margin = '0 0 0 0',
  showAlways = true,
}) => {
  // 呼び出し側で文字数によって本コンポーネントを出しわけするのが手間だったため制御
  if (!showAlways) return <>{children}</>

  const [show, setShow] = useState(false)
  const onClick = () => {
    setShow(!show)
  }
  const textAlign = `text-${align}`
  return (
    <>
      <div
        className={`from-gray-100 break-all ${
          show ? 'full-description' : 'short-description'
        }`}
      >
        {children}
      </div>

      <div className={`${textAlign} margin-style`}>
        <span className="text-fl-dark" onClick={onClick}>
          <span className="cursor-pointer toggle-button">
            {show ? '閉じる' : '続きを読む'}
          </span>
        </span>
      </div>
      <style jsx>{styles}</style>
      <style jsx>{`
        .short-description {
          height: ${showHeight || 75}px;
        }
        .toggle-button {
          font-size: ${fontSize};
          font-weight: ${fontWeight};
        }
        .margin-style {
          margin: ${margin};
        }
      `}</style>
    </>
  )
}
