import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setBreadcrumbs, Breadcrumb } from '@/stores/breadcrumbSlice'

export const useBreadcrumb = (breadcrumbs: Breadcrumb[]): void => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setBreadcrumbs(breadcrumbs))
  }, [breadcrumbs])
}
