import { AppDispatch } from '@/stores'
import { useDispatch } from 'react-redux'
import { initialHeaderState, modalFadeIn, setOpen } from '@/stores'

export const useShowLoginModal = () => {
  const dispatch = useDispatch<AppDispatch>()
  const showLoginModal = () => {
    dispatch(
      setOpen({
        ...initialHeaderState.open,
        modalBackdrop: true,
        modalLoginForm: modalFadeIn,
      })
    )
  }
  return showLoginModal
}
