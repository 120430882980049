export interface Error {
  code: number
  message: string
}

export interface ErrorResponse {
  result: boolean
  error?: Error
}

// eslint-disable-next-line
export const isErrorResponse = (item: any): item is ErrorResponse => {
  return item !== undefined && item?.error
}
