import { NaruhodoIcon, ReactionIcon } from '@/components/icon'
import { IconClipDark1 } from '@/components/icon/IconClipDark1'

interface Props {
  count: number
  done: boolean
  type: 'nice' | 'clip' | 'reaction'
  label: string
}

export const IconText: React.FC<Props> = ({ count, done, type, label }) => {
  const actionIcon = {
    nice: NaruhodoIcon,
    clip: IconClipDark1,
    reaction: ReactionIcon,
  }
  const Icon = actionIcon[type]
  return (
    <>
      <Icon
        color={done ? '#1A6DCC' : '#AAAAAA'}
        className="mr-2 w-[20px] h-[20px] "
      />
      {label !== '' && (
        <div
          className={`mr-2 -ml-2 text-xs ${
            done ? 'font-bold text-fl-blue' : 'text-fl-dark'
          }`}
        >
          {label}
        </div>
      )}
      <span
        className={`${
          done ? 'text-fl-blue font-bold' : 'text-fl-dark-1'
        } text-sm select-none`}
      >
        {count}
      </span>
    </>
  )
}
