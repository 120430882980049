/* eslint tailwindcss/no-custom-classname: off */
import { useEffect, useState } from 'react'
import {
  deleteCommentClip,
  deleteCommentNice,
  postCommentClip,
  postCommentNice,
  postCommentReaction,
} from '@/api/comment'
import { Nice } from '@/components/icon'
import {
  Comment as IComment,
  CommentAction,
  mapEmojiAction,
} from '@/types/comment'
import { IconText } from './IconText'

interface Props {
  comment: IComment
  showLabel?: boolean
}

interface Nice {
  done: boolean
  count: number
}

interface Clip {
  done: boolean
  count: number
}

interface Reaction {
  done: boolean
  count: number
  currentType: number
}

export const ActionButtons: React.FC<Props> = ({
  comment,
  showLabel = false,
}) => {
  const [nice, setNice] = useState<Nice>({
    done: comment.is_niced,
    count: comment.nice_count,
  })
  const [clip, setClip] = useState<Clip>({
    done: comment.is_clipped,
    count: comment.clip_count,
  })
  const [reaction, setReaction] = useState<Reaction>({
    done: comment.is_reacted,
    count: comment.reaction_count,
    currentType: comment.reacted_type,
  })
  const [openEmoji, setOpenEmoji] = useState(false)

  /**
   * リアクションエリア外をクリックしたときの処理
   */
  const onClickOutside = () => {
    setOpenEmoji(false)
  }

  /**
   * リアクションエリア以外をクリックしたときにリアクションエリアを非表示にする
   */
  useEffect(() => {
    document.addEventListener('click', onClickOutside)
    return () => {
      document.removeEventListener('click', onClickOutside)
    }
  }, [])

  /**
   * なるほどボタンをクリックしたときの処理
   */
  const onClickNice = async () => {
    if (nice.done) {
      setNice({ done: !nice.done, count: nice.count - 1 })
      await deleteCommentNice(`${comment.id}`)
    } else {
      setNice({ done: !nice.done, count: nice.count + 1 })
      await postCommentNice(`${comment.id}`)
    }
  }

  /**
   * クリップボタンをクリックしたときの処理
   */
  const onClickClip = async () => {
    if (clip.done) {
      setClip({ done: !clip.done, count: clip.count - 1 })
      await deleteCommentClip(`${comment.id}`)
    } else {
      setClip({ done: !clip.done, count: clip.count + 1 })
      await postCommentClip(`${comment.id}`)
    }
  }

  /**
   * リアクションボタンをクリックしたときの処理
   */
  const onClickReaction = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    // すでに絵文字を表示中の場合、非表示にする
    if (openEmoji) {
      setOpenEmoji(false)
      return
    }
    // 未リアクションの場合、リアクションエリアを表示する
    if (!reaction.done) {
      setOpenEmoji(true)
      return
    }
    // リアクション済みの場合、リアクションを取り消す
    setReaction({ done: false, count: reaction.count - 1, currentType: null })
    await postCommentReaction(comment.id, reaction.currentType)
  }

  /**
   * 各絵文字をクリックしたときの処理
   */
  const onClickEmoji = async (actionType: CommentAction) => {
    setReaction({
      done: true,
      count: reaction.count + 1,
      currentType: actionType,
    })
    setOpenEmoji(false)
    await postCommentReaction(comment.id, actionType)
  }

  return (
    <>
      {openEmoji && (
        <div className="absolute before:absolute before:bottom-[-10px] before:left-[65px] sm:before:left-[20px] m-4 mt-[-43px] ml-[-60px] sm:ml-[-13px] before:w-0 before:h-0 whitespace-nowrap bg-white rounded-md before:border-t-[10px] before:border-r-[5px] before:border-l-[5px] before:border-t-white before:border-r-transparent before:border-l-transparent shadow-flier before:border-l-solid before:content-[''] before:border-r-solid">
          {mapEmojiAction.map((v) => (
            <button
              key={`${v.emoji}-${v.actionType}`}
              className="mx-2 text-lg"
              onClick={() => onClickEmoji(v.actionType)}
            >
              {v.emoji}
            </button>
          ))}
        </div>
      )}
      <div className="flex whitespace-nowrap">
        <div
          className="flex items-center pr-4 cursor-pointer"
          onClick={onClickReaction}
        >
          <IconText
            type="reaction"
            count={reaction.count}
            done={reaction.done}
            label={showLabel ? 'リアクション' : ''}
          />
        </div>
        <div
          className="flex items-center pr-4 cursor-pointer"
          onClick={onClickNice}
        >
          <IconText
            type="nice"
            count={nice.count}
            done={nice.done}
            label={showLabel ? 'なるほど' : ''}
          />
        </div>

        <div className="flex items-center cursor-pointer" onClick={onClickClip}>
          <IconText
            type="clip"
            count={clip.count}
            done={clip.done}
            label={showLabel ? 'クリップ' : ''}
          />
        </div>
      </div>
    </>
  )
}
