import { apiProxy } from '@/libs/api/apiProxy'
import {
  PostCommentNice,
  PostCommentClip,
  DeleteCommentNice,
  DeleteCommentClip,
  PostCommentEditResponse,
  DeleteCommentResponse,
  PostCommentReaction,
  CommentAction,
} from '@/types/comment'

export const postCommentEdit = (
  summary_id: number,
  is_opening: boolean,
  comment: string,
  biz_group_cluster_id: number
): Promise<PostCommentEditResponse> => {
  // フロント側の制御上、非公開の場合はbiz_group_cluster_idを-1で扱っていたが、サーバー側では0で扱っているため0にする。
  if (!is_opening) {
    biz_group_cluster_id = 0
  }
  return apiProxy.post<PostCommentEditResponse>(
    `/api/comment/edit/${summary_id}`,
    { is_opening: is_opening, comment, biz_group_cluster_id }
  )
}

export const deleteComment = (
  summary_id: number
): Promise<DeleteCommentResponse> => {
  return apiProxy.del<DeleteCommentResponse>(`/api/comment/${summary_id}`)
}

export const postCommentNice = (
  comment_id: string
): Promise<PostCommentNice> => {
  return apiProxy.post<PostCommentNice>(`/api/comment/nice/${comment_id}`)
}

export const postCommentClip = (
  comment_id: string
): Promise<PostCommentClip> => {
  return apiProxy.post<PostCommentClip>(`/api/comment/clip/${comment_id}`)
}

export const deleteCommentNice = (
  comment_id: string
): Promise<DeleteCommentNice> => {
  return apiProxy.del<DeleteCommentNice>(`/api/comment/nice/${comment_id}`)
}

export const deleteCommentClip = (
  comment_id: string
): Promise<DeleteCommentClip> => {
  return apiProxy.del<DeleteCommentClip>(`/api/comment/clip/${comment_id}`)
}

export const postCommentReaction = (
  comment_id: number,
  actionType?: CommentAction
): Promise<PostCommentReaction> => {
  return apiProxy.post<PostCommentReaction>(
    `/api/comment/reactions/${comment_id}`,
    { action_type: actionType }
  )
}
