import {
  EMOJI_HEARTEYES,
  EMOJI_JOY,
  EMOJI_FIRE,
  EMOJI_CLAP,
  EMOJI_CRY,
  EMOJI_OPENMOUSE,
} from '@/utils/constant'

export const BIZ_GROUP_CLUSTER_ID_ALL = 0
export const BIZ_GROUP_CLUSTER_ID_PRIVATE = -1

interface Summary {
  rank: number
  id: number
  title: string
  subtitle: string
  subpos: number
  free: number
  ja: boolean
  en: boolean
  new: boolean
  percent: number
  image: string
}

export interface User {
  id: number
  name: string
  srp_id: string
  image: string
  introduction: string
  is_followed: boolean
  is_following: boolean
  is_requesting: boolean
  is_opening: boolean
}

export interface Comment {
  id: number
  summary: Summary
  comment: string
  created: string
  updated: string
  create_elapsed: string
  elapsed: string
  user: User
  is_opening: boolean
  is_niced: boolean
  nice_count: number
  is_clipped: boolean
  clip_count: number
  is_reacted: boolean
  reaction_count: number
  reacted_type: CommentAction
  biz_group_cluster_id: number
}

export enum CommentAction {
  NONE = 0,
  NICE = 1,
  CLIP = 2,
  SAVE = 3,
  HEARTEYES = 4,
  JOY = 5,
  FIRE = 6,
  CLAP = 7,
  CRY = 8,
  OPENMOUSE = 9,
}

export const isReaction = (type: CommentAction) => {
  return [
    CommentAction.HEARTEYES,
    CommentAction.JOY,
    CommentAction.FIRE,
    CommentAction.CLAP,
    CommentAction.CRY,
    CommentAction.OPENMOUSE,
  ].some((v) => v === type)
}

export const mapEmojiAction = [
  { emoji: EMOJI_HEARTEYES, actionType: CommentAction.HEARTEYES },
  { emoji: EMOJI_JOY, actionType: CommentAction.JOY },
  { emoji: EMOJI_FIRE, actionType: CommentAction.FIRE },
  { emoji: EMOJI_CLAP, actionType: CommentAction.CLAP },
  { emoji: EMOJI_CRY, actionType: CommentAction.CRY },
  { emoji: EMOJI_OPENMOUSE, actionType: CommentAction.OPENMOUSE },
]

export const getEmojiForCommentAction = (actionType: CommentAction): string => {
  const emojiMapping = {
    [CommentAction.HEARTEYES]: EMOJI_HEARTEYES,
    [CommentAction.JOY]: EMOJI_JOY,
    [CommentAction.FIRE]: EMOJI_FIRE,
    [CommentAction.CLAP]: EMOJI_CLAP,
    [CommentAction.CRY]: EMOJI_CRY,
    [CommentAction.OPENMOUSE]: EMOJI_OPENMOUSE,
  }
  return emojiMapping[actionType]
}

export interface PostCommentEditResponse {
  result: boolean
  comment?: string
  error?: Error
}

export interface DeleteCommentResponse {
  result: boolean
  error?: Error
}

export interface PostCommentNice {
  result: boolean
  comment: Comment
}

export interface PostCommentClip {
  result: boolean
  comment: Comment
}

export interface PostCommentReaction {
  result: boolean
  comment: Comment
}

export type DeleteCommentNice = PostCommentNice

export type DeleteCommentClip = PostCommentClip

export interface GetCommentResponse {
  result: boolean
  total?: number
  comments: Comment[]
  error?: Error
}

export interface GetCommentSimpleResponse {
  result: boolean
  comment_id: number
  comment: string
  is_opening: boolean
  biz_group_cluster_id: number
  error?: Error
}
