import css from 'styled-jsx/css'

const styles = css`
  button {
    position: relative;
    color: #293c6f;
    padding: 8px 30px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    background-color: #f3f6fa;
    border: 1px solid #d9e2ed;
    border-radius: 52px;
  }
  button:focus {
    outline: none;
  }
  button:active {
    outline: none;
  }
  button:hover {
    outline: none;
    background-color: #e7edf5;
  }
  button:visited {
    outline: none;
  }
  #arrow-right-aqua {
    width: 7px;
    height: 7px;
    border: 0px;
    border-top: 2px solid #293c6f;
    border-right: 2px solid #293c6f;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: calc(50% - 3px);
    right: 10px;
  }
`

interface Props {
  text: string
  onClick?: () => void
}

export const CircularButton: React.FC<Props> = ({ text, onClick = null }) => {
  return (
    <>
      <button type="button" className="rounded" onClick={onClick}>
        <span className="text-sm">{text}</span>
        <span id="arrow-right-aqua"></span>
      </button>
      <style jsx>{styles}</style>
    </>
  )
}
